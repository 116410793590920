import React from 'react'
import classNames from 'classnames'

import './contentBox.module.css'

const ContentBox = (props) => (
  <div styleName={'contentBox'}>

      {!props.imageRight &&
        <div styleName={classNames('leftContent', {'border': props.imageBorder})}>
          <div styleName={'imageBox'} style={{width: props.imageWidth ? props.imageWidth : '300px'}}>
            <img src={props.image} alt={props.imageAlt} />
          </div>
        </div>
      }

      <div styleName={'rightContent'}>
        <div styleName={'textContainer'}>
          <div styleName={'title'}>
            {props.title}
          </div>
          <div styleName={'text'}>
            {props.text}
          </div>
        </div>
      </div>

      {props.imageRight &&
        <div styleName={classNames('leftContent', {'border': props.imageBorder})}>
          <div styleName={'imageBox'} style={{width: props.imageWidth ? props.imageWidth : '300px'}}>
            <img src={props.image} alt={props.imageAlt} />
          </div>
        </div>
     }

  </div>
)

export default ContentBox
