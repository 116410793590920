import React from 'react'
//import Link from 'gatsby-link'
import Page from '../components/Page/Page'
import ContentBox from '../components/ContentBox/ContentBox'


import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const TraditionPage = ({ data }) => {
  const traditionElements = data.allContentfulTraditionEintrag.nodes;
  return (
    <Page
      title={'Tradition'}
      renderCallback={() => (
        <div>
          {traditionElements.map(element => (
              <ContentBox
                image={`https://${element.image.file.url}`}
                imageAlt={element.image.description}
                text={documentToReactComponents(element.description.json)}
                title={element.title}
                imageWidth={element.imageWidth}
                imageRight={element.imageRight}
                key={element.image.file.url}
              />
          ))}
      </div>
      )}
    />
  );
};

export default TraditionPage

export const traditionQueryFragment = graphql `
  fragment TraditionQueryFragment on ContentfulTraditionEintrag {
      title
      imageRight
      imageWidth
      description {
        json
      }
      image {
        title
        file {
          url
        }
        description
      }
  }
`

export const traditionQuery = graphql `
  query traditionDEQuery {
    allContentfulTraditionEintrag(sort: {fields: contentfulid}, filter: {node_locale: {eq: "de" }}) {
      nodes {
        ...TraditionQueryFragment
      }
    }
  }
`
